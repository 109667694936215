import { Link } from 'react-router-dom'
const UserCard = ({ info }) => {
    return ( 
        <div className=' rounded-lg w-[85%] m-auto'>
            <Link  onClick={() => { window.location.replace(`/profile/${info.email}`)}}>
                <div className='bg-greenGray rounded-lg p-4 text-white font-bold text-xl flex gap-2 justify-start items-center'>
                    <h1 className='border-r-2 p-3'>{info.username}</h1>
                    <span>Posts: {info.posts.length}</span>
                </div>
            
            </Link>
        </div>
    );
}

export default UserCard;