import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo-CP.png'
function footer() {
return (
    <div className='bg-greenGray w-[100%] flex justify-center items-center h-[150px]'>
        <Link onClick={() => window.location.replace('/')}>
        <img className='w-[300px] md:w-[175px]' src={Logo} alt='logo'/>
        </Link>
    </div>
)
}

export default footer