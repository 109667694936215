import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { QUERY_ALL_CATEGORIES } from '../../utils/queries'
import { Link } from 'react-router-dom'
function CategoryLinks() {
    const { loading, data } = useQuery(QUERY_ALL_CATEGORIES)
    const [cat, setCat] = useState('')
    const handleCat = (e) => {
        const { value } = e.target
        setCat(value)
    }
    useEffect(() => {
        if(!loading) {
            setCat(data.categories[0]._id)
        }
        
    }, [loading])
return (
    <div className='flex sm:flex-col gap-3 justify-center items-center bg-white p-3 rounded-md shadow-lg w-[100%] min-w-[400px] sm:min-w-[100px] mt-4'>
        <h1 className='text-lg sm:text-sm'>Categories</h1>
        {
            loading ? <></> : (
                <select onChange={handleCat} className='border rounded-lg p-2 sm:text-sm' placeholder='states'>
                    
                        {data.categories.map(each => {
                            return <option key={each._id} value={each._id} data-id={each.name}>{each.name}</option>
                        })}
                    
                </select>
            )
        }
        <Link className='bg-greenGray p-1 rounded-md text-white' to={`/category/${cat}`}>Search</Link>
    </div>
)
}

export default CategoryLinks