import React from 'react'

function CommentCard({ info }) {

return (
    <div className='border border-greenGray/[.5] rounded-md shadow-md w-[100%] p-3 bg-white'>
        <div>
            <h2 className='text-lg'>{info.commentAuthor}</h2>
            <span className='text-sm border-greenGray border-b-2 p-1'>{info.createdAt}</span>
            <p className='p-5 mt-2 text-lg'>{info.commentText}</p>
        </div>
    </div>
)
}

export default CommentCard