import { Link } from "react-router-dom";

const MainCalcPage = () => {
    return ( 
        <main className="min-h-screen flex flex-col justify-start items-center mt-12">
            <h1 className="font-bold text-5xl mb-12">Calculators</h1>
            <ol className="flex flex-col gap-6 justify-center items-start shadow-lg p-10 bg-greenGray w-[95%] max-w-[1144px] rounded-xl text-white font-bold underline list-decimal text-xl">
                <li><Link to='/mortgageCalc'>Loan Amortization Calculator</Link></li>
                <li><Link to='/rentalCalc'>Rental Cashflow Calculator</Link></li>
                <li><Link to='/creditCardCalc'>Credit Card Calculator</Link></li>
            </ol>
        </main>
    );
}

export default MainCalcPage;