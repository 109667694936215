import { useState, useEffect } from 'react'
import sanityClient from '../../../client'
import { useParams } from 'react-router-dom'
import imageUrlBuilder from '@sanity/image-url'
import Loading from '../../Loading'
import BlockContent from '@sanity/block-content-to-react'

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
    return builder.image(source)
}

const BlogPost = () => {
    const [data, setData] = useState(null)
    const { slug } = useParams()
    useEffect(() => {
        sanityClient.fetch(
            `
            *[slug.current == $slug]{
                title,
                slug,
                publishedAt,
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                body,
                "name": author->name,
                "authorImage": author->image
            }
            `,
            { slug }
        ).then(info => setData(info[0])).catch(console.error)
    }, [slug])

if (!data) {
    return <Loading />
} 
// console.log(data)
    return ( 
    <section className=' flex flex-col min-h-screen w-[65%] sm:w-[80%] xs:w-[95%] mx-auto mt-10'>
        <h2 className='text-[3em] lg:text-[2em] md:text-[1.3em] sm:text-[1.1em] mt-10 mx-auto p-4'>{data.title}</h2>
        <div className='h-2 w-[75%] bg-greenGray mb-10 rounded-lg mx-auto'></div>
        <div className='mx-auto w-[65%] mb-10'>
            <img className='rounded-md shadow-lg' src={data.mainImage.asset.url} alt='img'/>
        </div>
        <div className='p-10 mx-auto'>
            <BlockContent 
            className='flex flex-col gap-2 text-xl sm:text-md xs:text-sm xs:leading-[40px] leading-[40px]'
            blocks={data.body}
            projectId='d7ur4dux'
            dataset='production'
            />
        </div>
        <div className='mb-10 flex-wrap border-2 border-greenGray rounded-lg flex justify-start gap-6 p-4 items-center'>
            <img className='w-[65px] rounded-full aspect-square' src={urlFor(data.authorImage).url()} alt='img' />
            <span className='font-bold text-md'> Author: {data.name}</span>
            <span className='font-bold text-md'> publisehd At: {data.publishedAt.slice(0, 10)}</span>
        </div>
    </section> 
    );
}

export default BlogPost;