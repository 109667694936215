import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { REMOVE_POST } from '../../utils/mutations'
import Auth from '../../utils/auth'

function PostCard({ info }) {
    // console.log(info)
    const [removePost] = useMutation(REMOVE_POST)
    const [message, setMessage] = useState('hidden')
    // handles the mutation to remove a post 
    const handleDelete = async () => {
        const { data } = await removePost({
            variables: { id: info._id, authorId: info.authorId }
        })
        window.location.reload()
    }

    return (
    <div className='w-[100%] border border-greenGray/[.1] rounded-md p-5 shadow-md flex justify-between items-center g-2 bg-white sm:overflow-x-hidden md:flex-col '>
        <Link onClick={() => {
            if (Auth.loggedIn()) {
                window.location.replace(`/post/${info._id}`)
            } else {
                setMessage('absolute') 
            }
        }} className='w-[75%]'>
        <div className='flex flex-col justify-center items-start'>
            <h1 className='text-[2em] md:text-[1.5em] font-bold w-[100%] capitalize text-left border-b-2 border-greenGray/[.7] mb-2 title'>{info.name}</h1>
            <h1 className='font-bold border border-greenGray/[.5] p-1 rounded-md bg-greenGray text-white sm:text-sm'>{info.categoryName}</h1>
            <h2 className='font-bold text-[1em]'>{info.createdAt}</h2>
            <h3 className='font-bold'>{info.author}</h3>
            <h3 className='font-bold'>Comments: {info.comments.length}</h3>
        </div>
        </Link>
            {
                Auth.loggedIn() ? (
                    info.authorId === Auth.getProfile().data.email && window.location.pathname === `/profile/${Auth.getProfile().data.email}` ? <button onClick={handleDelete} id='delete-btn' className='bg-red text-white p-2 rounded-md md:mt-6'>Delete Post</button> : <></>
                ) : <></>
            }
            <div className={`${message} top-0 left-0 right-0 h-screen flex justify-center items-center`}>
                <div className='text-white bg-greenGray p-4 gap-6 shadow-xl rounded-3xl flex flex-col justify-center items-center'>
                    <h1 className='font-bold text-xl'>You must be logged in to view posts.</h1>
                    <div className='flex gap-3'>
                        <Link className='bg-white text-greenGray p-3 rounded-3xl font-bold' to='/login'>Login</Link>
                        <button onClick={()=> setMessage('hidden')} className='bg-red rounded-3xl p-3'>Close</button>
                    </div>
                    
                </div>
            </div>
    </div>
    
) 


}

export default PostCard