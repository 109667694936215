import React from 'react'
import { useState, useEffect } from 'react'
import { USER_LOGIN } from '../../utils/mutations'
import { useMutation } from '@apollo/client'
import Auth from '../../utils/auth'
import { Link }from 'react-router-dom'

function Login() {
    const [login] = useMutation(USER_LOGIN)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        document.title = 'Concrete Plaza - Login'
    }, [])

    useEffect(() => {
        console.log(errorMessage)
    }, [errorMessage])
    // handles the login mutation
    const getLoggedIn = async (e) => {
        e.preventDefault()
        try {
            const { data } = await login({
            variables: { email: email, password: password },
        })
        Auth.login(data.login.token)
        setEmail('')
        setPassword('')
        } catch (err) {
            setErrorMessage(err.message)
        }
        
    }

    const handleEmailChange = (event) => {
        const { value } = event.target
        setEmail(value)
    }
    
    const handlePassChange = (event) => {
        const { value } = event.target
        setPassword(value)
    }

    if( Auth.loggedIn()) {

        window.location.replace('/')

    } else {

        return (

            <div className='min-h-screen'>
                <form onSubmit={getLoggedIn} className='w-[65%] max-w-[800px] m-auto mt-[10%] border border-greenGray/[.1] bg-white shadow-lg h-[375px] rounded-md p-4 flex flex-col gap-6 justify-center items-center'>
                    <h1 className='text-[2em] font-bold'>Login</h1>
                    <input onChange={handleEmailChange} className='border border-greenGray/[.5] rounded-md w-[100%] p-4' type='email' placeholder='Email'/>
                    <input onChange={handlePassChange} className='border border-greenGray/[.5] rounded-md w-[100%] p-4' type='password' placeholder='Password'/>
                    <button className='border p-3 rounded-md bg-greenGray text-white font-bold text-xl' type='submit'>Submit</button>
                    {
                        !errorMessage ? <></> : <span className='text-xl text-red font-bold'>{errorMessage}</span>
                    }
                    <div><span>Don't have an account? </span><Link to='/createAccount' className='text-greenGray font-bold underline'>Create One.</Link></div>
                </form>
                
            </div>
)
    }

}

export default Login