let list = [
    {
        name: 'Rich Dad Poor Dad',
        coverImg: 'https://m.media-amazon.com/images/P/B07C7M8SX9.01._SCLZZZZZZZ_SX500_.jpg',
        author: 'Robert Kiyosaki',
        link: "https://www.amazon.com/Rich-Dad-Poor-Teach-Middle/dp/1612681131/ref=sr_1_1?crid=1H4CR7A657FU1&amp;keywords=rich+dad+poor+dad+book&amp;qid=1680547362&amp;sprefix=rich+dad%252Caps%252C118&amp;sr=8-1&_encoding=UTF8&tag=45097803-20&linkCode=ur2&linkId=a8f1311b2438f8c3f5ccf38385c73f1a&camp=1789&creative=9325",

    },
    {
        name: 'Buy, Rehab, Rent, Refinance, Repeat: The BRRRR Rental Property Investment Strategy Made Simple',
        coverImg: 'https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/41kdNzQaAWL.jpg',
        author: 'David Greene',
        link:"https://www.amazon.com/Buy-Rehab-Rent-Refinance-Repeat/dp/1947200089/ref=sr_1_2?crid=35S6I8W309RDD&amp;keywords=buy+rehab+rent+refinance+repeat&amp;qid=1680547625&amp;sprefix=buy+re%252Caps%252C115&amp;sr=8-2&_encoding=UTF8&tag=45097803-20&linkCode=ur2&linkId=d2a1cccdf64772a0901bf34fcb41d7e6&camp=1789&creative=9325"
    }
]
export default list