import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Auth from '../../utils/auth'
import Hamburger from '../../assets/menu.png'
import MobileMenu from './MobileMenu'
import HamIcon from './Hamburger'
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import PP from '../../assets/generic-pp.webp'
import Logo from '../../assets/logo-CP.png'
// import { useAuth0 } from '@auth0/auth0-react'

function NavBar() {
    const [mobile, setMobile] = useState('')
    const [hidden, setHidden] = useState('')
    const [hamburger, setHamburger] = useState('')
    const [menu, setMenu] = useState('hidden')

    // logs the user out
    const handleLogout = () => {
        Auth.logout()
    }

    // handles the navbar responsiveness
    const handleResize = () => {
        if (window.innerWidth > 569) {
            setMobile(false)
            setHidden('flex')
            setHamburger('hidden')
        } else {
            setMobile(true)
            setHidden('hidden')
            setHamburger('flex')
        }
    }

    // toggles the visibility of the mobile menu
    const handleMenu = () => {
        console.log(menu)
        if (menu === 'flex') {
            setMenu('hidden')
            document.body.style.overflowY = "scroll"
        } else {
            setMenu('flex')
            document.body.style.overflowY = "hidden"
        }
    }

    // toggles if certain links are visible
    const handleLinks = () => {
        setMenu('hidden')
        // console.log(menu)
    }

    useEffect(() => {
        handleResize()
    }, [])
    
    useEffect(() => {
        window.addEventListener('resize', handleResize)
    })


        return (
    <>
    {
        Auth.loggedIn() ? (
        <nav className={`flex w-[90%] relative m-auto mt-5 items-center bg-greenGray text-white rounded-lg shadow-lg p-5`}>
        {/* div for logo and navigation buttons */}
            <div className={`flex md:flex-col sm:flex-row sm:justify-between gap-5 items-center w-[100%] `}>
                <Link className='' onClick={() => window.location.replace('/')}>
                    <img className='w-[300px] md:w-[175px]' src={Logo} alt='logo'/>
                </Link>
                <button onClick={handleMenu} className={`w-[40px] ${hamburger}`}><HamIcon/></button>
                <div className={`${hidden} gap-6 text-xl md:text-sm flex justify-between items-center ml-auto md:ml-0 mr-5`}>
                    <div className='flex gap-6 items-center'>
                    {/* <LogoutButton /> */}
                    <Link onClick={() => window.location.replace('/')}>Home</Link>
                    {/* <Link onClick={() => window.location.replace('/calculatorPage')}>Calculators</Link> */}
                    {/* <Link onClick={() => window.location.replace('/blog')}>Blog</Link> */}
                    <Link onClick={() => window.location.replace('/createPost')}>Create a Post</Link>
                    <Link onClick={handleLogout} to='#'>Logout</Link>
                    </div>
                    <div className='flex items-center '>
                        {/* <Link className='p-5 text-xl font-bold md:text-sm sm:p-0' to={`/profile/${Auth.getProfile().data._id}`}>Profile: <span className='hover:underline'>{Auth.getProfile().data.username}</span></Link> */}
                        <Link className='p-0 text-xl font-bold md:text-sm sm:p-0' onClick={() => window.location.replace(`/profile/${Auth.getProfile().data.email}`)}>
                            {/* <img className=' rounded-full w-[50px] h-[50px]' src={PP} alt='profile-pic'/> */}
                            <div className='w-[50px] h-[50px] rounded-full overflow-hidden bg-white flex justify-center items-center'><h1 className='text-greenGray text-[1em] font-bold uppercase'>{Auth.getProfile().data.username[0] || 'User'}</h1></div>
                        </Link>
                    </div>
                </div>
                <div className={`${menu} rounded-md absolute right-[50%] min-h-[600px] translate-x-[50%] bg-greenGray top-[100px] p-5 shadow-2xl w-[100%]`}>
                    <MobileMenu 
                        user={Auth.getProfile().data} 
                        links={handleLinks} 
                        menu={menu} 
                        auth={Auth.loggedIn()}
                        logout={handleLogout}
                    />
                </div>
            </div>
            
        </nav>
        ) : (
        <nav className={`flex w-[90%] relative m-auto mt-5 justify-between items-center bg-greenGray text-white rounded-lg shadow-lg p-5`}>
        {/* div for logo and navigation buttons */}
            <div className='flex md:flex-col sm:flex-row sm:justify-between gap-5 items-center w-[100%]'>
                <Link onClick={() => window.location.replace('/')}>
                        <img className='w-[300px] md:w-[175px]' src={Logo} alt='logo'/>
                    </Link>
                <button onClick={handleMenu} className={`w-[40px] ${hamburger}`}><HamIcon/></button>
                <div className={`${hidden} gap-6 text-xl md:text-sm flex justify-between items-center ml-auto mr-5`}>
                    <Link to='/login'>Login</Link>
                    {/* <LoginButton /> */}
                    <Link onClick={() => window.location.replace('/')}>Home</Link>
                    {/* <Link onClick={() => window.location.replace('/calculatorPage')}>Calculators</Link> */}
                    {/* <Link onClick={() => window.location.replace('/blog')}>Blog</Link> */}
                </div>
                <div className={`${menu} rounded-md absolute right-[50%] min-h-[600px] translate-x-[50%] bg-greenGray top-[100px] p-5 shadow-2xl w-[100%]`}>
                    <MobileMenu auth={Auth.loggedIn()}/>
                </div>
            </div>
        </nav>
    )
    }
    
    </>
)

    

}

export default NavBar