import React from 'react'

function Loading() {
return (
    <div className='h-screen w-screen flex justify-center items-center'>
        <div className='animate-bounce w-[150px] h-[150px] rounded-full text-xl bg-greenGray text-white font-bold flex justify-center items-center'>
        Loading  
        </div>
        
    </div>
)
}

export default Loading