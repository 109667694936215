import React from 'react'
import PostCard from '../postComps/PostCard'
import { Link } from 'react-router-dom'
function ProfilePosts({ param, info }) {
return (
    <section className='border border-greenGray/[.1] shadow-md rounded-sm flex flex-col gap-2 w-[100%] max-h-[700px] min-h-[500px] justify-start items-center p-5 overflow-y-scroll mb-8 bg-white '>
        {
            info.length <= 0 ? <Link onClick={() => window.location.replace('/createPost')} className='bg-greenGray rounded-md text-white p-5 text-2xl w-[100%] text-center'>Create A Post</Link> : ( 
                info.map((each ,index) => {
                return <PostCard key={index} info={each}/>
            })
            )
        }
    </section>
)
}

export default ProfilePosts