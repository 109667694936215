import React, { useState, useEffect } from 'react'
import { NEW_POST } from '../../utils/mutations'
import { QUERY_ALL_CATEGORIES } from '../../utils/queries'
import { useMutation, useQuery } from '@apollo/client'
import Auth from '../../utils/auth'
import Loading from '../Loading'


function CreatePost() {
    const [addPost, error] = useMutation(NEW_POST)
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')
    const { loading, data } = useQuery(QUERY_ALL_CATEGORIES)
    const [cat, setCat] = useState('')

    useEffect(() => {
        document.title = 'Concrete Plaza - New Post'
        
    }, [])
    
    useEffect(() => {
        if(!loading) {
            setCat(data.categories[0]._id)
            // console.log(cat)
            // console.log(Auth.getProfile().data.username)
        }
        
    }, [loading])

    // useEffect(() => {
    //     console.log(cat)
    // }, [cat])

    const handleTitle = (e) => {
        const { value } = e.target
        setTitle(value)
    }
    const handleBody = (e) => {
        const { value } = e.target
        setBody(value)
    }
    const handleCat = (e) => {
        const { value } = e.target
        setCat(value)
    }
    // inputs all of the states into the mutation to create a new post
    const handleForm = async (e) => {
        e.preventDefault()
        try {
            const { data: info } = await addPost({
        variables: { name: title, body: body, author: Auth.getProfile().data.username, authorId: Auth.getProfile().data.email, catId: cat, email: Auth.getProfile().data.email },
        })
        setTitle('')
        setBody('')
        window.location.replace(`/profile/${Auth.getProfile().data.email}`)
        } catch (err) {
            console.log(error, err)
        }
        
    }
if (loading) {

    return <Loading/>

} else {

    return (

    <form onSubmit={handleForm} className='flex flex-col max-w-[800px] w-[75%] m-auto mt-10 mb-[400px] gap-[20px] '>
        <h1 className='m-auto text-[3em] sm:text-[2em]'>Create a Post</h1>
            {
                loading ? <></> : (
                    <select onChange={handleCat} className='border rounded-md border-greenGray/[.3] p-2' placeholder='states'>
                        
                            {data.categories.map(each => {
                                return <option key={each._id} value={each._id} data-id={each.name}>{each.name}</option>
                            })}
                        
                    </select>
                )
            }
        
        <input onChange={handleTitle} className='border p-2 rounded-md border-greenGray/[.3] text-[2em] sm:text-[1.5em]' placeholder='Post Title' type='text'/>
        <textarea onChange={handleBody} className='border p-2 rounded-md border-greenGray/[.3] text-[1.7em] sm:text-[1.2em]' placeholder='Write about it...' type='text'/>
        <button className=' p-2 rounded-sm bg-greenGray text-white text-xl' type='submit'>Create Post</button>
    </form>
)
}

}

export default CreatePost