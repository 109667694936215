import { gql } from '@apollo/client'

export const CREATE_USER = gql`
mutation AddUser($email: String!, $username: String!, $password: String!) {
    addUser(email: $email, username: $username, password: $password) {
    token
    user {
        profilePic
    }
    }
}
`
export const FOLLOW = gql`
mutation AddFollower($email: String!, $followedId: String!) {
    addFollower(email: $email, followedId: $followedId) {
    username
    }
}
`
export const UNFOLLOW = gql`
mutation RemoveFollower($email: String!, $followedId: String!) {
    removeFollower(email: $email, followedId: $followedId) {
    username
    }
}
`

export const USER_LOGIN = gql`
mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
    token
    user {
        username
        _id
    }
    }
}
`
// add _id in the future
export const NEW_POST = gql`
mutation AddPost($name: String!, $email: String!, $body: String!, $author: String!, $catId: String!) {
    addPost(name: $name, email: $email, body: $body, author: $author, catId: $catId) {
    name
    }
}
`

export const NEW_PROP_POST = gql`
mutation AddPropertyPost($address: String!, $id: String!, $description: String!, $author: String!, $images: [Imageinput]!) {
    addPropertyPost(address: $address, _id: $id, description: $description, author: $author, images: $images) {
    _id
    address
    }
}
`

export const NEW_COMMENT = gql`
mutation AddComment($commenttext: String!, $commentAuthor: String!, $id: String!) {
    addComment(commenttext: $commenttext, commentAuthor: $commentAuthor, _id: $id) {
    commentAuthor
    }
}
`
export const EDIT_ABOUT = gql`
mutation AddAbout($about: String!, $email: String!) {
    addAbout(about: $about, email: $email) {
    about
    email
    }
}
`
export const EDIT_OCCUPATION = gql`
mutation Mutation($occupation: String!, $email: String!) {
    addOccupation(occupation: $occupation, email: $email) {
    occupation
    }
}
`
export const EDIT_STATE = gql`
mutation Mutation($state: String!, $email: String!) {
    addState(state: $state, email: $email) {
    state
    }
}
`

export const REMOVE_POST = gql`
mutation RemovePost($id: String!, $authorId: String!) {
    removePost(_id: $id, authorId: $authorId) {
    name
    }
}
`