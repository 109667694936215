import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { QUERY_USER } from '../../utils/queries'
import { useParams } from 'react-router-dom'
import ProfileImgAndName from '../profileComps/ProfileImgAndName'
import ProfilePosts from '../profileComps/ProfilePosts'
import ProfileAbout from '../profileComps/ProfileAbout'
import Loading from '../Loading'
import Auth from '../../utils/auth'

function Profile() {
    const { profileId } = useParams()
    //queries user data to populate profile screen
    const { loading, data } = useQuery(QUERY_USER, {
        variables: { email: profileId }
    })

    useEffect(() => {
        if (loading) {
            
            document.title = `Concrete Plaza - Profile`
        } else {
            // console.log(data)
            document.title = `Concrete Plaza - ${Auth.getProfile().data.username}'s Profile`
        }
    }, [loading])

    if(loading) {

        return <Loading/>

    } else {

        if(Auth.loggedIn()) {

        return (

            <div className='w-[90%] m-auto mt-5 flex flex-col gap-1 mb-[50px]'>
                {/* profile img and pic section */}
                <ProfileImgAndName info={data} param={profileId}/>
                {/* About section for profile */}
                <ProfileAbout info={data.user} user={Auth.getProfile().data} param={profileId}/>
                {/* Profile posts section */}
                <ProfilePosts param={profileId} info={data.user.posts} user={Auth.getProfile().data} />
            </div>

) 
        } else {
            window.location.replace('/')
        }
        
    }

}

export default Profile