import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'

import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Post from './components/pages/Post';
import Profile from './components/pages/Profile';
import PropPost from './components/pages/PropPost';
import NavBar from './components/navbar/NavBar'
import CreateAccount from './components/pages/CreateAccount';
import CreatePost from './components/pages/CreatePost';
import Auth from './utils/auth'
import CategoryPage from './components/pages/CategoryPage';
import Footer from './components/footer/footer';
import FollowingPage from './components/pages/FollowingPage';
import FollowerPage from './components/pages/FollowerPage';
import BlogHome from './components/pages/blog/BlogHome';
import BlogPost from './components/pages/blogpost/BlogPost'
import MainCalcPage from './components/pages/calculatorPages/MainCalcPage';


let origin =
  window.location.host.split(':').length > 1
    ? window.location.origin.slice(0, -4) + '5500'
    : window.location.origin
const graphqlUri = origin + '/graphql'

const httpLink = createHttpLink({
  uri: graphqlUri,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('id_token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  // Set up our client to execute the `authLink` middleware prior to making the request to our GraphQL API
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/profile/:profileId" element={<Profile />} />
          <Route path="/" element={<Home />} />
          <Route path="/post/:postId" element={<Post />} />
          <Route path="/propertypost/:propertypostId" element={<PropPost />} />
          <Route path="/createAccount" element={<CreateAccount />} />
          <Route path="/createPost" element={<CreatePost />} />
          <Route path="/category/:catId" element={<CategoryPage />} />
          <Route path="/followingPage/:userId" element={<FollowingPage />} />
          <Route path="/followerPage/:userId" element={<FollowerPage />} />
          <Route path="/calculatorPage" element={<MainCalcPage />} />
          {/* <Route path="/blog" element={<BlogHome />} />
          <Route path="/blog/:slug" element={<BlogPost />} /> */}
        </Routes>
        <Footer/>
      </Router>
    </ApolloProvider>
  );
}

export default App;
