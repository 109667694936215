import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Auth from '../../utils/auth'
import { useMutation } from '@apollo/client'
import { FOLLOW, UNFOLLOW } from '../../utils/mutations'
// import S3FileUpload from 'react-s3';
import PP from '../../assets/generic-pp.webp'
// import { useAuth0 } from '@auth0/auth0-react'
// import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;

function ProfileImgAndName({ info, param }) {
    const [addFollower] = useMutation(FOLLOW)
    const [removeFollower] = useMutation(UNFOLLOW)

    const handleFollow = () => {
        try {
            const { data } = addFollower({
                variables: { email: Auth.getProfile().data.email, followedId: info.user._id }
            })
            window.location.reload()
        } catch (err) {
            console.log(err)
        }
    }

    const handleUnFollow = () => {
        try {
            const { data } = removeFollower({
                variables: { email: Auth.getProfile().data.email, followedId: info.user._id }
            })
            window.location.reload()
        } catch (err) {
            console.log(err)
        }
    }

return (

    <section className='p-5 border border-greenGray/[.1] shadow-md flex flex-col items-center justify-center gap-3 bg-white'>
        <div className='flex md:flex-col items-center justify-center gap-3'>
            {/* <img className='w-[100px] h-[100px] rounded-full overflow-hidden bg-gray-200' src={info.user.profilePic ? info.user.profilePic : PP } alt='profile pic' /> */}
            <div className='w-[100px] h-[100px] rounded-full overflow-hidden bg-greenGray flex justify-center items-center'><h1 className='text-white text-[3em] font-bold uppercase'>{info.user.username[0] || 'user'}</h1></div>
            
            <div className='flex flex-col justify-center items-center'>
                <h1 className='text-2xl font-bold capitalize' >{info.user.username}</h1>
                <div className='flex gap-3'>
                    <span className='text-greenGray underline'><Link onClick={()=> window.location.replace(`/followingPage/${info.user.email}`)}>Following: {info.user.following.length}</Link></span>
                    <span className='text-greenGray underline'><Link onClick={()=> window.location.replace(`/followerPage/${info.user.email}`)}>Followers: {info.user.followers.length}</Link></span>
                </div>
                {
                    param !== Auth.getProfile().data.email ? (
                        <div className='flex gap-4 mt-2'>
                        <button onClick={handleFollow} className='p-2 bg-greenGray text-white font-bold rounded-lg'>Follow</button>
                        <button onClick={handleUnFollow} className='p-2 bg-red text-white font-bold rounded-lg'>Unfollow</button>
                        </div>
                    ) : <></>
                }
            </div>
        </div>    
    </section>
)
}

export default ProfileImgAndName