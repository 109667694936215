import React from 'react'

function Hamburger() {
return (
    <div className='flex flex-col justify-center items-center gap-2'>
        <span className='w-[30px] h-[3px] bg-white rounded-lg'></span>
        <span className='w-[30px] h-[3px] bg-white rounded-lg'></span>
        <span className='w-[30px] h-[3px] bg-white rounded-lg'></span>
    </div>
)
}

export default Hamburger