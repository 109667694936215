import React from 'react'
import { useState } from 'react'
import { EDIT_ABOUT, EDIT_OCCUPATION, EDIT_STATE } from '../../utils/mutations'
import { useMutation } from '@apollo/client'



function ProfileAbout({ param, info, user }) {
    const [about, setAbout] = useState(info.about)
    const [occupation, setOccupation] = useState(info.occupation)
    const [location, setLocation] = useState(info.state)
    const [addAbout] = useMutation(EDIT_ABOUT)
    const [addOccupation] = useMutation(EDIT_OCCUPATION)
    const [addState] = useMutation(EDIT_STATE)
    const states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']
    const occupations = ['Investor', 'Realtor', 'Real Estate Agent', 'Getting Started']
    
    const handleAbout = (e) => {
        const { value } = e.target
        setAbout(value)
    }
    const handleOccupation = (e) => {
        const { value } = e.target
        setOccupation(value)
    }
    const handleLocation = (e) => {
        const { value } = e.target
        setLocation(value)
    }
    const handleAboutBtn = async () => {
        const { data } = await addAbout({
            variables: { about: about, email: user.email }
        })
        window.location.reload()
    }
    const handleOccBtn = async () => {
        const { data } = await addOccupation({
            variables: { occupation: occupation, email: user.email }
        })
        window.location.reload()
    }
    const handleLocBtn = async () => {
        const { data } = await addState({
            variables: { state: location, email: user.email }
        })
        window.location.reload()
    }

return (
    <section className='flex md:flex-col gap-5 justify-center items-center border border-greenGray/[.1] shadow-md p-5 bg-white'>
        <div className='flex flex-col gap-2 justify-center items-center'>
            
            { 
            param === user.email ? <div className='flex sm:w-[90%] flex-col justify-center items-center gap-2 mb-2 '><span className='text-xl font-bold border p-2 border-greenGray rounded-lg'><span className='border-b-2 border-greenGray'>About:</span></span><textarea onChange={handleAbout} className='border sm:w-[90%] border-greenGray/[.4] rounded-md p-2' placeholder={`${about}`}/><button onClick={handleAboutBtn} className='bg-greenGray text-white rounded-md p-2'> Save About</button></div> : <span className='border border-greenGray p-3 rounded-lg flex flex-col justify-center items-center'><span className='font-bold text-xl border-b-2 border-greenGray mb-2'>About:</span> <span>{about}</span></span>
            }
        </div>
        <div className='flex flex-col gap-2 justify-center items-center'>
            <span className='border border-greenGray p-3 rounded-lg flex flex-col justify-center items-center'><span className='font-bold text-xl border-b-2 border-greenGray mb-2'>Occupation:</span> <span>{occupation}</span></span>
            { 
            param === user.email ? (
            <div className='flex sm:w-[90%] flex-col justify-center items-center gap-2 mb-2'>
                {/* <input onChange={handleOccupation} className='border rounded-md p-2' placeholder='Edit your occupation.'/> */}
                <select onChange={handleOccupation} className='border sm:w-[90%] border-greenGray/[.4] rounded-lg p-2' placeholder='states'>
                    {
                        occupations.map(each => {
                            return <option key={each} value={each}>{each}</option>
                        })
                    }
                </select>
                <button onClick={handleOccBtn} className='bg-greenGray text-white  rounded-md p-2'> Save Occupation</button>
            </div>
            ) : <></>
            }
        </div>
        <div className='flex flex-col gap-2 justify-center items-center'>
        <span className='border border-greenGray p-3 rounded-lg flex flex-col justify-center items-center'><span className='font-bold text-xl border-b-2 border-greenGray mb-2'>Location:</span> <span>{location}</span></span>
            { 
            param === user.email ? (
            <div className='flex flex-col sm:w-[90%] justify-center items-center gap-2 mb-2'>
                {/* <input onChange={handleLocation} className='border rounded-md p-2' placeholder='Edit your location.'/> */}
                <select onChange={handleLocation} className='border sm:w-[90%] border-greenGray/[.4] rounded-lg p-2' placeholder='states'>
                    {
                        states.map(each => {
                            return <option key={each} value={each}>{each}</option>
                        })
                    }
                </select>
                <button onClick={handleLocBtn} className='bg-greenGray text-white rounded-md p-2'> Save Location</button></div>
                ) : <></>
            }
        </div>
    </section>
)
}

export default ProfileAbout