import React from 'react'
import { Link } from 'react-router-dom'
// import LoginButton from './LoginButton'
// import LogoutButton from './LogoutButton'
function MobileMenu({ logout, links, user, auth }) {
return (
    <>
    {
        auth ? (
            <div className='flex flex-col justify-start items-center w-full gap-8 text-[3em] mt-[3rem] sm:text-[1.5em]'>
                <Link onClick={() => window.location.replace(`/profile/${user.email}`)}>
                    {/* <img className=' rounded-full w-[50px] h-[50px]' src={user.picture} alt='profile-pic'/> */}
                    <div className='w-[50px] h-[50px] rounded-full overflow-hidden bg-white flex justify-center items-center'><h1 className='text-greenGray text-[1em] font-bold uppercase'>{user.username[0] || 'User'}</h1></div>
                </Link>
                {/* <LogoutButton/> */}
                <Link className='text-3xl' onClick={() => {logout()}} >Logout</Link>
                <Link className='text-3xl' onClick={() => {window.location.replace('/')}} >Home</Link>
                <Link className='text-3xl' onClick={() => {window.location.replace('/createPost')}}>Create a Post</Link>
                {/* <Link className='text-xl' onClick={() => window.location.replace('/blog')}>Blog</Link> */}
                {/* <Link className='text-3xl' onClick={() => window.location.replace('/calculatorPage')}>Calculators</Link> */}
                
            </div>
        ) : (
            <div className='flex flex-col justify-start items-center w-full gap-8 text-[3em] mt-[3rem]'>
                {/* <LoginButton /> */}
                <Link className='text-3xl' onClick={() => {window.location.replace('/login')}}>Login</Link>
                <Link className='text-3xl' onClick={() => {window.location.replace('/createAccount')}}>Create Account</Link>
                {/* <Link className='text-xl' onClick={() => window.location.replace('/blog')}>Blog</Link> */}
                {/* <Link className='text-3xl' onClick={() => window.location.replace('/calculatorPage')}>Calculators</Link> */}
            </div>
        )
    }
    </>
    
)
}

export default MobileMenu