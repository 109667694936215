import React from 'react'
import List from '../../assets/bookList/BookList'
function BookList() {
return (
    <section className='flex flex-col justify-center items-center max-w-[400px] border border-greenGray/[.1] shadow-lg p-2 flex-1 bg-white rounded-md'>
        <h1 className='text-[2em] underline mb-4'>Reading List</h1>
        {List.map((each, index) => {
            return <div key={index} className='flex flex-col justify-center items-center p-5 rounded-md shadow-md w-[65%]'>
                        <div className='flex flex-col justify-center items-center'>
                            <img className='w-[200px] flex flex-1' src={each.coverImg} alt='img'/>
                            {/* <h1>{each.name}</h1> */}
                        </div>
                        <p className='text-xl mt-3 text-center lg:text-lg'>{each.author}</p>
                        <a className='bg-greenGray p-2 rounded-md text-white font-bold mt-2' href={each.link} target='_blank' rel="noreferrer">Buy Now</a>
                    </div>
        })}
    </section>
)
}

export default BookList