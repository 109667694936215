import React, { useEffect, useState } from 'react'
import AllPosts from '../postComps/AllPosts'
import BookList from '../homeComps/BookList'
import CategoryLinks from '../homeComps/CategoryLinks'
import Loading from '../Loading'
// import Auth from '../../utils/auth'
// import Loading from '../Loading'

function Home() {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(false)
        document.title = 'Concrete Plaza - Home'
        // if(Auth.loggedIn()) {
        //     console.log(Auth.getProfile().data)
            
        // }
    }, [])

    if(loading) {
        return <Loading/>
    }
    return (
        <div className='w-[100%] flex flex-col justify-center items-center mb-[100px] min-h-screen'>
            <div className='mt-6 border border-greenGray shadow-md'>
                <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=kindle&banner=15C85235MBQCTDB5ZK82&f=ifr&linkID=50fd249084bc1afedcf2d6406e4c9e17&t=45097803-20&tracking_id=45097803-20" width="320" height="50" scrolling="no" border="0" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
            </div>
            <section className='flex justify-center items-center w-[30%] md:w-[90%] md:m-auto mt-5'>
                <CategoryLinks />
            </section>
            <div className='w-[90%] flex justify-end items-start mt-10 mb-10 m-auto md:flex-col md:justify-center md:h-full'>
                <section className='flex justify-center items-start w-[50%] md:w-[98%] md:m-auto md:mt-4'> 
                    <AllPosts/>
                </section>
                <section className='flex justify-center items-center w-[30%] md:w-[90%] md:m-auto md:mt-4'>
                    <BookList />
                </section>
            </div>
        
        
        
    </div>
)

        


}

export default Home