import React from 'react'
import { useEffect, useState } from 'react'
import { QUERY_ALL_POSTS } from '../../utils/queries'
import { useQuery } from '@apollo/client'
import PostCard from './PostCard'
import Loading from '../Loading'
function AllPosts() {
    const [pag, setPag] = useState(1)
    // queries for all posts to populate the homepage
    const { loading, data } = useQuery(QUERY_ALL_POSTS, {
        variables: { currentPage: pag }
    })
// useEffect(() => {
//     console.log(pag)
//     if(!loading) {
//         console.log(data.posts.length)
//     }
    
// }, [pag])
const handleNextPag = () => {
    if (data.posts.length >= 10) {
        setPag(pag + 1)
    } else {
        return
    }
    
}
const handlePrevPag = () => {
    if (pag > 1 ) {
        setPag(pag - 1)
    } else return
    
}

    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
    <div className='w-[100%] flex flex-col gap-4 items-center mb-5 mt-5 mr-3 p-2'>
        {
            data.posts.map((each, index) => {
                return <PostCard key={index} info={each}/>
            })
        }
        <div className='flex mt-4 justify-center items-center'>
            
                    <div className='flex gap-3'>
                    <button className='bg-greenGray text-white p-2 rounded-md font-bold' onClick={handlePrevPag}>Prev Page</button>
                    <button className='bg-greenGray text-white p-2 rounded-md font-bold' onClick={handleNextPag}>NextPage</button>
                    </div>
                
            
        </div>
        
    </div>
)
    }

}

export default AllPosts