import React from 'react'
import { useState, useEffect } from 'react'
import { CREATE_USER } from '../../utils/mutations'
import { useMutation } from '@apollo/client'
import Auth from '../../utils/auth'


function CreateAccount() {
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [rePassword, setRePassword] = useState('')
const [userName, setUserName] = useState('')
const [addUser] = useMutation(CREATE_USER)


useEffect(() => {
    document.title = 'Concrete Plaza - Create Account'
}, [])

const handleEmailChange = (event) => {
    const { value } = event.target
    setEmail(value)
}

const handleUserChange = (event) => {
    const { value } = event.target
    setUserName(value)
}

const handlePassChange = (event) => {
    const { value } = event.target
    setPassword(value)
}
const handleRePassChange = (event) => {
    const { value } = event.target
    setRePassword(value)
}
// inputs all of the states into the useMutation to create a new user account
const handleForm = async (e) => {
    e.preventDefault()
    if (rePassword === password) {
        try {
        const { data } = await addUser({
            variables: { email: email, username: userName, password: password },
        })
        Auth.login(data.addUser.token)
        setEmail('')
        setPassword('')
        setRePassword('') 
        } catch (err) {
            console.log(err)
        }
        

    } else {
        alert('passwords need to match or you need a longer')
    }
}
return (
    <div className='w-[65%] mb-[400px] max-w-[800px] m-auto mt-[10%] border border-greenGray/[.1] bg-white rounded-md h-[550px] shadow-lg'>
        <form onSubmit={handleForm} className='flex flex-col justify-center items-center h-[100%] w-[85%] m-auto gap-4'>
            <h1 className='text-[2em] font-bold'>Create An Account</h1>
            <input onChange={handleEmailChange} className='border border-greenGray/[.5] rounded-md w-[100%] p-4' type='text' placeholder='Email'/>
            <input onChange={handleUserChange} className='border border-greenGray/[.5] rounded-md w-[100%] p-4' type='text' placeholder='UserName'/>
            <input onChange={handlePassChange} className='border border-greenGray/[.5] rounded-md w-[100%] p-4' type='password' placeholder='Password'/>
            <input onChange={handleRePassChange} className='border border-greenGray/[.5] rounded-md w-[100%] p-4' type='password' placeholder='Re-type Password'/>
            <button className='border p-3 rounded-md bg-greenGray text-white font-bold text-xl' type='submit'>Submit</button>
        </form>
    </div>
)
}

export default CreateAccount