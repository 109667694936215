import { gql } from '@apollo/client'

export const QUERY_USER = gql`
query Query($email: String) {
    user(email: $email) {
      _id
      username
      email
      password
      about
      profilePic
      posts {
        _id
        name
        author
        body
        authorId
        createdAt
        comments {
          _id
          commentText
          commentAuthor
          createdAt
          postId
        }
        categoryName
      }
      followers {
        _id
        username
        email
        password
        about
        profilePic
        state
        occupation
        posts {
          _id
          name
          author
          body
          authorId
          createdAt
          comments {
            _id
            commentText
            commentAuthor
            createdAt
            postId
          }
          categoryName
        }
        friends {
          _id
          username
          email
          password
          about
          profilePic
          state
          occupation
        }
        followers {
          _id
          username
          email
          password
          about
          profilePic
          state
          occupation
        }
        following {
          _id
          username
          email
          password
          about
          profilePic
          state
          occupation
        }
      }
      following {
        _id
        username
        email
        posts {
          _id
          name
          author
          body
          authorId
          createdAt
          comments {
            _id
            commentText
            commentAuthor
            createdAt
            postId
          }
          categoryName
        }
      }
      state
      occupation
    }
  }
`
export const FOLLOWING_POSTS = gql`
query SeeFollowingPosts($email: String) {
    seeFollowingPosts(email: $email) {
    following {
        posts {
        _id
        author
        authorId
        body
        categoryName
        createdAt
        name
        comments {
            _id
            commentAuthor
            commentText
            createdAt
            postId
        }
        }
    }
    }
}
`
export const QUERY_ALL_CATEGORIES = gql`
query Categories {
    categories {
    name
    _id
    posts {
        _id
        name
        createdAt
        author
        authorId
        categoryName
    }
    }
}
`

export const QUERY_CATEGORY = gql`
query Query($categoryId: ID) {
    category(id: $categoryId) {
    name
    _id
    posts {
        _id
        name
        author
        body
        authorId
        createdAt
        categoryName
        comments {
            _id
            commentText
            commentAuthor
            createdAt
            }
    }
    }
}
`

export const QUERY_ALL_POSTS = gql`
query Posts($currentPage: Int) {
    posts(currentPage: $currentPage){
    _id
    name
    author
    body
    createdAt
    authorId
    categoryName
    comments {
        _id
        commentText
        commentAuthor
        createdAt
    }
    }
}
`

export const QUERY_POST = gql`
query Post($postId: ID) {
    post(id: $postId) {
    body
    author
    createdAt
    _id
    authorId
    name
    comments {
        _id
        commentAuthor
        commentText
        createdAt
    }
    }
}
`

export const QUERY_PROP_POST = gql`
query PropertyPost($propertyPostId: ID) {
    propertyPost(id: $propertyPostId) {
    _id
    description
    address
    author
    createdAt
    authorId
    comments {
        _id
        commentText
        commentAuthor
        createdAt
    }
    images {
        url
    }
    }
}
`