import { useQuery } from "@apollo/client";
import { QUERY_USER } from '../../utils/queries';
import { useEffect } from 'react'
import Loading from "../Loading";
import UserCard from "../userComps/UserCard";
import { useParams } from 'react-router-dom'

const FollowingPage = () => {

    const { userId } = useParams()
    // queries user data to fetch their following
    const { loading, data } = useQuery(QUERY_USER,
        { variables: { email: userId } })

useEffect(() => {
    document.title = "Concrete Plaza - Network's Posts"
}, [])

if (loading) {

    return <Loading />

} else {

    // console.log(data.user.following)

    return ( 

        <div className="min-h-screen mt-10">
            <h1 className="m-auto w-[25%] text-center text-2xl font-bold mb-10 border-b-2 border-greenGray pb-2">Following</h1>
            <div className="flex flex-col gap-4">
                {
                    data.user.following.length > 0 ?data.user.following.map(each => {
                        return <UserCard key={each._id} info={each} />
                    }) : <div className="m-auto w-[25%] text-center text-xl"> Not following anyone yet...</div>
                }
            </div>
            
        </div>
    );
}
    
}
export default FollowingPage;