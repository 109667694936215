import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { QUERY_CATEGORY } from '../../utils/queries'
import PostCard from '../postComps/PostCard'
import Loading from '../Loading'
import { Link } from 'react-router-dom'


function CategoryPage() {

    const { catId } = useParams()
    //queries for categories
    const { loading, data } = useQuery(QUERY_CATEGORY, {
        variables: { categoryId: catId }
    })

useEffect(() => {
    if(!loading) {
        // console.log(data)
        document.title = `Concrete Plaza - ${data.category.name}`
    }
}, [loading])

if (loading) {

    return <Loading/>

} else if (data.category.posts <= 0) {

    return <h1 className='text-xl h-screen m-auto mt-10 w-[50%] text-center'>No posts in this category yet... <Link  onClick={() => window.location.replace('/createPost')}><span className='text-greenGray font-bold underline text-2xl'>Go create One!</span></Link></h1>

} else {
    return (
    <div className='flex flex-col justify-start gap-8 items-center mt-10 min-h-[700px] w-[100%]'>
        {
            loading ? <div>Loading...</div> : (
                data.category.posts.map((each, index) => {
                    return <div className='w-[85%]' key={index}><PostCard info={each} /></div>
                })
            )
        }
    </div>
)
}

}

export default CategoryPage