import React, { useState, useEffect } from 'react'
import { QUERY_POST } from '../../utils/queries'
import { NEW_COMMENT } from '../../utils/mutations'
import { useQuery, useMutation} from '@apollo/client'
import { useParams, Link } from 'react-router-dom'
import CommentCard from '../postComps/CommentCard'
import Loading from '../Loading'
import Auth from '../../utils/auth'

function Post() {
    const { postId } = useParams()
    const [addComment] = useMutation(NEW_COMMENT)
    const [comment, setComment] = useState('')

    // queries post data to show the post
    const { loading, data } = useQuery(QUERY_POST, {
        variables: { postId },
    })

    useEffect(() => {
        if (loading) {
            
            document.title = `Concrete Plaza - Post`
        } else {
            console.log(data)
            document.title = `Concrete Plaza - ${data.post.author}'s Post`
        }
    }, [loading])

    const handleComment = (e) => {
        const { value } = e.target
        setComment(value)
    }
    // handles the add comment mutation.
    const handleForm = async (e) => {
        e.preventDefault()
        const { data } = await addComment({
            variables: { commenttext: comment, commentAuthor: Auth.getProfile().data.username, id: postId, postId: postId }
        })
        setComment('')
        window.location.reload()
    }

    if (loading) {

        return <Loading />

    } else {

        return (

            <div className='w-[85%]  p-5 border border-greenGray/[.1] shadow-md m-auto mt-5 rounded-sm mb-[400px] bg-white '>
                <div className='border-b-2 border-greenGray flex flex-col gap-2'>
                <h1 className='text-[3em] sm:text-[2em] capitalize post-title'>Title: {data.post.name}</h1>
                <div className='flex justify-center items-center gap-2 w-[100px] ml-5'>
                    <span>Author:</span>
                    <Link className='underline hover:text-blue-600 w-[75px] ' onClick={() => window.location.replace(`/profile/${data.post.authorId}`)}>
                        <h3 className='pb-2 text-2xl'>{data.post.author}</h3>
                    </Link>
                </div>
                
                </div>
                <p className='mt-4 border border-greenGray/[.1] shadow-md p-5 text-lg bg-white'>{data.post.body}</p>
                <div className='border border-greenGray/[.5] rounded-md shadow-md mt-4 p-3'>
                    <h1 className='underline text-xl'>Comments</h1>
                    <div className='flex flex-col mt-3 gap-3'>
                        {
                        data.post.comments.length <= 0 ? <h2>Be the first Comment!</h2> :  data.post.comments.map((each, index) => {
                                return <CommentCard key={index} info={each}/>
                            })
                        }
                        <div className='w-[100%]'>
                            <form onSubmit={handleForm} className='w-[100%]'>
                                <textarea onChange={handleComment} className='w-[100%] border border-greenGray/[.5] rounded-md shadow-md p-2 mt-2' placeholder='Add Comment' />
                                <button className='border rounded-md w-[100%] bg-greenGray text-white p-2' type='submit'>Add Comment</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    

)
    }

}

export default Post